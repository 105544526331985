@import "src/scss/abstracts/variables";
@import "src/scss/abstracts/mixins";

.intro-box-overlapping-wrap {
	padding: 0;
	position: relative;
}

.intro-box-overlapping {
	width: 100%;
	position: relative;
	@include bgImg();

	@include for-size(tablet-landscape-down) {
		display: none;
	}
}

.intro-box-overlapping__text-wrap {
	padding: 5% 0;
	position: relative;
	margin-left: 0px;
	margin-right: 0px;
	display: flex;
	align-items: center;

	@include for-size(desktop-down) {
		padding: 7% 0;
	}
	@include for-size(tablet-landscape-down) {
		display: none;
	}
}

.intro-box-overlapping__text {
	position: relative;
	background-color: $color-white;
	color: $color-black;
	width: toRem(650);
	padding: toRem(60);
	left: toRem(90);
	border-radius: toRem(15);

	@include for-size(desktop-down) {
		left: toRem(60);
	}

	h1 {
		margin-bottom: toRem(20);
	}

	&-description {

		p {

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.intro-box-overlapping__text-mobile {
	position: relative;
	background-color: $color-white;
	color: $color-black;
	padding: toRem(60);
	display: none;

	@include for-size(tablet-landscape-down) {
		display: block;
	}
	@include for-size(tablet-down) {
		padding: toRem(30);
	}

	h1 {
		margin-top: toRem(10);
		margin-bottom: toRem(20);
	}
}

.intro-box-overlapping__img-mobile {
	display: none;
	padding: 0;

	@include for-size(tablet-landscape-down) {
		display: block;
	}

	img {
		width: 100%;
		display: block;
	}
}
